import React, { useEffect } from 'react';
import MediaDownload from '../../components/MediaDownload';
 
function ResourcesPage() {
  const url = '/media/resources/resources.json';

  const [resources, setResources] = React.useState([]);

useEffect(()=>{
  async function fetchResources() {
    const response = await fetch(url);
    const data = await response.json();
    setResources(data);
  }
  fetchResources();
}, [])

  return (
    <div>
      {resources.map((resource) => (
        <MediaDownload
          description={resource}
          downloadLink={`/media/resources/${resource}`}
        />
      ))}
    </div>
  );
}

export default ResourcesPage;

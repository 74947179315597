import React from 'react'
import Modal from 'react-modal';
import LongArrowLeft from '..//LongArrowLeft';
import { OFFICERS_INFO } from '../../constants';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

function BookingModal({onClose}) {
  return (
    <Modal
    isOpen={true}
    onRequestClose={onClose}
    contentLabel="My dialog"
    className="mymodal"
    overlayClassName="myoverlay"
    closeTimeoutMS={500}
    style={{ zIndex: '99 !important', maxHeight: '80vh' }}
  >
    <div>
      Who would you like to schedule an appointment with?
      <br />
      <br />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 8,
          justifyContent: 'center',
        }}
      >
        {OFFICERS_INFO.map((officer) => {
          return (
            <div style={{ width: '45%' }}>
              <a
                key={officer.id}
                href={`https://calendly.com/${officer.id}/${
                  officer.default ? officer.default + 'min' : ''
                }`}
                target="_blank"
                className="c-block__button o-button o-button--outline btn-block"
                style={{ fontSize: 12 }}
                rel="noreferrer"
              >
                {officer.displayName}
                <LongArrowLeft />
              </a>
            </div>
          );
        })}
      </div>
    </div>
    <hr />
    <br />
    <button onClick={onClose} className="background-tree-fbtn-block">
      Close
    </button>
  </Modal>
  )
}
 
export default NiceModal.create(()=>{
    const modal = useModal();
    if (!modal.visible) {
        return null;
    }
    return <BookingModal onClose={() => void modal.hide()} />;
});
import React from 'react'

export default function Drawer() {
    return (
        <div className="c-drawer l-grid l-grid--7-col">
        <div className="c-drawer__toggle js-toggle-parent u-theme--background-color-trans--darker this-is-active">
          <div className="u-icon o-icon__close">
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="l-grid-wrap--6-of-7 l-grid-item--s--6-col c-drawer__container u-spacing u-theme--background-color--darker">
          <div className="c-drawer__nav">
            <div className="c-drawer__nav-primary">
              <nav
                className="c-primary-nav c-priority-nav"
                role="navigation"
                instance="1"
              >
                <ul className="c-primary-nav__list c-priority-nav__list">
                  <li className="c-primary-nav__list-item">
                    <a
                      href="https://cc.adventist.org"
                      className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base this-is-active"
                    >
                      Cape Conference - Home
                    </a>
                  </li>
                </ul>
                <span
                  className="c-priority-nav__dropdown-wrapper"
                  aria-haspopup="false"
                >
                  <button
                    aria-controls="menu"
                    type="button"
                    className="js-toggle-menu c-priority-nav__dropdown-toggle c-priority-nav--is-hidden"
                  ></button>
                  <ul
                    aria-hidden="true"
                    className="c-priority-nav__dropdown"
                  ></ul>
                </span>
              </nav>
            </div>
          </div>
          <div className="c-drawer__logo">
            <span className="u-icon u-icon--l u-path-fill--white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 253.71 227.63"
              >
                <title>icon-logo</title>
                <path d="M67.68,120.38c-.1-9.91,2.8-18.41,20.8-36.41l39-39c10.4-10.4,19.1-19.29,19.1-29V.67c0-.89-1.1-.89-1.2,0-2.6,12.4-7.5,17.3-17.8,27.61L82.28,73.47c-17.1,17-19.5,35.1-15.8,46.91C66.78,121.38,67.68,121.47,67.68,120.38Zm64.2,38.9c0,.89,1,.89,1.2,0,2.6-12.5,7.6-17.4,17.8-27.61L166,116.78c19.8-19.9,10.7-39-.9-44.31-1-.5-1.4.31-.6,1,9.3,8,6.5,21.5-5.2,33.2L151,115c-10.4,10.31-19.1,19.2-19.1,29Zm81.8,26.6L168,178.17l-17.1,17.11c-10.4,10.39-19,19.3-19,29V227c0,.7.9.9,1.2.11,3.2-8.7,13.9-18.11,33.5-14.8,0,0,85.4,14.8,86.1,14.8a.9.9,0,0,0,1-.61.79.79,0,0,0-.2-.8c-.3-.39-39.8-39.8-39.8-39.8Zm-107.5,1h15a.66.66,0,0,0,.6-.6c0-7.9-6-13.31-30-9.2L40,186S.58,225.37.28,225.78a.79.79,0,0,0-.1,1.1.81.81,0,0,0,.8.2c.7-.11,86.1-14.8,86.1-14.8,19.6-3.31,30.3,6,33.5,14.8.3.8,1.2.7,1.2-.11v-29.5a.66.66,0,0,0-.6-.6l-15,.1a.64.64,0,0,1-.6-.6v-8.9a.65.65,0,0,1,.6-.6Zm26.8,25.9a36.3,36.3,0,0,1,6.9-15.81h-7.5a.65.65,0,0,0-.6.61v15.2c0,.89,1.1.89,1.2,0Zm13.6-158.6c0-.91-1.1-.91-1.2,0-2.6,12.39-7.5,17.29-17.8,27.6l-39.8,39.69c-19.8,19.81-10.7,39,.9,44.31,1,.5,1.4-.4.6-1-9.3-8-6.5-21.61,5.2-33.2l33-33c10.4-10.4,19.1-19.3,19.1-29Zm-19.1,17.49c10.4-10.3,19-19.2,19.1-29V27.27c0-.9-1.1-.9-1.2,0-2.6,12.4-7.5,17.4-17.8,27.7L86.68,95.78c-19.8,19.8-23.3,39-13.2,51.4.7.8,1.4.3,1-.7-6.7-15.1,9.3-31.1,16.9-38.7Zm37.6,82.5-14.3,14.2c-6.5,6.5-12.3,12.4-15.8,18.4h14.1l1.7-1.7,20.5-20.5c17.1-16.9,19.5-35,15.8-46.8-.3-1-1.2-1.1-1.2,0,.1,9.9-2.8,18.4-20.8,36.4Zm-2.8-24-11.4,11.5c-10.4,10.4-19.1,19.4-19.1,29.11v15.3c0,.89,1.1.89,1.2,0,2.6-12.4,7.5-17.4,17.8-27.7L167,142.17c19.8-19.8,23.3-39,13.2-51.4-.7-.8-1.4-.3-1,.7C185.88,106.57,169.88,122.57,162.28,130.17Zm83.5,68.2h1.1l1.7,3h1.5l-2-3.3a2,2,0,0,0,1.3-2c0-1.5-.8-2.2-2.7-2.2h-2.2v7.5h1.3v-3Zm0-3.5h.8c1,0,1.4.3,1.4,1.2,0,.7-.5,1.2-1.4,1.2h-.9v-2.4Zm1,9a6.3,6.3,0,1,0-6.3-6.3,6.08,6.08,0,0,0,5.87,6.3Zm0-11.7a5.4,5.4,0,1,1,0,10.8,5.31,5.31,0,0,1-5.4-5.23h0v-.16a5.24,5.24,0,0,1,5.11-5.39h.29Z"></path>
              </svg>
            </span>
          </div>
          <div className="c-drawer__about">
            <div className="c-drawer__about-left u-spacing">
              <p>
                The mission of the Cape Conference of the Seventh-day
                Adventist Church is to proclaim the everlasting gospel of
                our Lord and Saviour Jesus Christ as symbolized by the three
                angels’ messages of Revelation 14:6-12, through the power of
                the Holy Spirit to all peoples within its territory, leading
                them to accept Jesus Christ as personal Saviour and to unite
                with His Church and nurturing them in preparation for His
                soon return.
              </p>
            </div>
            <div className="c-drawer__about-right u-spacing--half">
              <h3 className="u-font--secondary--s u-text-transform--upper">
                <strong>Learn More:</strong>
              </h3>
              <p className="u-spacing--half">
                <a
                  href="https://adventist.org"
                  target="_blank"
                  className="u-link--white"
                  rel="noreferrer"
                >
                  Adventist.org
                </a>
                <a
                  href="https://sid.adventist.org"
                  target="_blank"
                  className="u-link--white"
                  rel="noreferrer"
                >
                  Southern Africa-Indian Ocean Division of Seventh-day
                  Adventists
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
}

import {   Route, Routes, } from 'react-router-dom'; 
 
import React from 'react';
import LandingPage from './pages/LandingPage';
import ReportsPage from './pages/ReportsPage';
import ResourcesPage from './pages/ResourcesPage';
 
export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/resources" element={<ResourcesPage />}/> 
      <Route path="/reports" element={<ReportsPage />}/> 
      <Route path="/" element={<LandingPage />}/>
    </Routes>
  );
};

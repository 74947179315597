import React from 'react'

const MediaDownload = ({ description, downloadLink }) => {
    return (
      <div class="c-cta-block c-block u-border--left u-theme--border-color--darker--left can-be--dark-dark  u-background-color--gray--light bottom-m-1">
        <div class="c-cta-block__content c-block__content u-spacing">
          <div class="c-cta-block__group c-block__group u-spacing">
            <p class="c-cta-block__description c-block__description u-font--secondary u-font--secondary--m">
              {description}
            </p>
          </div>
          <div class="c-cta-block__buttons c-block__buttons">
            <a
              href={downloadLink}
              target="_blank"
              rel="noreferrer"
              class="c-block__button o-button o-button--outline"
            >
              Download
              <span class="u-icon u-icon--m u-path-fill--base u-space--half--left">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>o-arrow__long--left</title>
                  <path
                    d="M18.29,8.59l-3.5-3.5L13.38,6.5,15.88,9H.29v2H15.88l-2.5,2.5,1.41,1.41,3.5-3.5L19.71,10Z"
                    fill="#9b9b9b"
                  ></path>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  };
  

export default MediaDownload
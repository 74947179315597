import React, {useEffect} from 'react';
import MediaDownload from '../../components/MediaDownload';

function ReportsPage() {
  const url = '/media/reports/reports.json';

  const [reports, setReports] = React.useState([]);

useEffect(()=>{
  async function fetchResources() {
    const response = await fetch(url);
    const data = await response.json();
    setReports(data);
  }
  fetchResources();
}, [])

  return (
    <div>
      {reports.map((report) => (
        <MediaDownload
          description={report}
          downloadLink={`/media/reports/${report}`}
        />
      ))}
    </div>
  );
}

export default ReportsPage;

import React from 'react'
import acms from '../../assets/images/acms.svg';
import headset from '../../assets/images/headset.svg';
import envelopeOpen from '../../assets/images/envelope-open.svg';
import coins from '../../assets/images/coins.svg';
import handHolding from '../../assets/images/hand-holding-usd.svg';
import book from '../../assets/images/book.svg';
import ActionLink from '../../components/ActionLink';
import NiceModal from '@ebay/nice-modal-react';
import BookingModal from '../../components/BookingModal';
import LongArrowLeft from '../../components/LongArrowLeft';

function LandingPage() {
  return (
    <section
    id="top"
    className="l-main__content l-grid l-grid--7-col l-grid-wrap--6-of-7 u-spacing--double--until-xxlarge u-padding--zero--sides u-shift--left--1-col--at-large "
  >
    <article className="c-article l-grid-item l-grid-item--l--4-col l-grid-item--xl--3-col">
      <div className="c-article__body">
        <div className="text u-spacing u-space--top l-grid">
          <div className="l-grid-item--6-col l-grid-item--l--4-col l-grid-item--xl--6-col u-shift--right--1-col--at-large u-shift--left--1-col--standard u-no-gutters ">
            <section className="c-section c-section__block-feed u-spacing--double">
              <div className="l-grid l-grid--7-col u-no-gutters">
                <div className="l-grid-item l-grid-item l-grid-item--s--3-col l-grid-item--l--2-col">
                  <div className="c-media-block c-block c-block__stacked u-space--right u-space--double--bottom">
                    <div className="c-block__content u-spacing u-border--left u-color--gray u-theme--border-color--darker--left">
                      <div className="u-spacing c-block__group card-relative">
                        <div className="u-width--100p u-spacing">
                          <h3 className="c-block__title u-theme--color--darker u-font--primary--m ">
                            <a
                              href="https://cc.adventist.org/forums/business/"
                              className="c-block__title-link u-theme--link-hover--dark"
                            >
                              One-on-One
                            </a>
                          </h3>
                          <p className="c-block__description">
                            Book an appointment with a member of our
                            Team
                          </p>
                        </div>
                        <div className="c-block__meta hide"></div>

                        <div style={{ width: '100%' }}>
                          <button
                            className="c-block__button o-button o-button--outline"
                            type="button"
                            onClick={()=>NiceModal.show(BookingModal)}
                          >
                            Schedule
                            <LongArrowLeft />
                          </button>
                          <img
                            src={headset}
                            className="card-image"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-grid-item l-grid-item l-grid-item--s--3-col l-grid-item--l--2-col">
                  <div className="c-media-block c-block c-block__stacked u-space--right u-space--double--bottom">
                    <div className="c-block__content u-spacing u-border--left u-color--gray u-theme--border-color--darker--left">
                      <div className="u-spacing c-block__group card-relative">
                        <div className="u-width--100p u-spacing">
                          <h3 className="c-block__title u-theme--color--darker u-font--primary--m ">
                            <a
                              href="https://envelope.cc.adventist.org"
                              className="c-block__title-link u-theme--link-hover--dark"
                            >
                              ACMS
                            </a>
                          </h3>
                          <p className="c-block__description">
                            Adventist Church Management Systems
                          </p>
                        </div>
                        <div className="c-block__meta hide"></div>
                        <ActionLink
                          url="https://www.acmsnet.org"
                          label="Launch"
                          img={acms}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="l-grid-item l-grid-item l-grid-item--s--3-col l-grid-item--l--2-col">
                  <div className="c-media-block c-block c-block__stacked u-space--right u-space--double--bottom">
                    <div className="c-block__content u-spacing u-border--left u-color--gray u-theme--border-color--darker--left">
                      <div className="u-spacing c-block__group card-relative">
                        <div className="u-width--100p u-spacing">
                          <h3 className="c-block__title u-theme--color--darker u-font--primary--m ">
                            <a
                              href="https://envelope.cc.adventist.org"
                              className="c-block__title-link u-theme--link-hover--dark"
                            >
                              Tithe Envelope
                            </a>
                          </h3>
                          <p className="c-block__description">
                            Submit scanned photos of your tithe
                            envelopes
                          </p>
                        </div>
                        <div className="c-block__meta hide"></div>
                        <ActionLink
                          url="https://envelope.cc.adventist.org"
                          label="Launch"
                          img={envelopeOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-grid-item l-grid-item l-grid-item--s--3-col l-grid-item--l--2-col">
                  <div className="c-media-block c-block c-block__stacked u-space--right u-space--double--bottom">
                    <div className="c-block__content u-spacing u-border--left u-color--gray u-theme--border-color--darker--left">
                      <div className="u-spacing c-block__group card-relative">
                        <div className="u-width--100p u-spacing">
                          <h3 className="c-block__title u-theme--color--darker u-font--primary--m ">
                            <a
                              href="https://tf.cc.adventist.org"
                              className="c-block__title-link u-theme--link-hover--dark"
                            >
                              Trust Fund
                            </a>
                          </h3>
                          <p className="c-block__description">
                            Submit tithe and offering collection
                            details
                          </p>
                        </div>
                        <div className="c-block__meta hide"></div>
                        <ActionLink
                          url="https://tf.cc.adventist.org"
                          label="Launch"
                          img={coins}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-grid-item l-grid-item l-grid-item--s--3-col l-grid-item--l--2-col">
                  <div className="c-media-block c-block c-block__stacked u-space--right u-space--double--bottom">
                    <div className="c-block__content u-spacing u-border--left u-color--gray u-theme--border-color--darker--left">
                      <div className="u-spacing c-block__group card-relative">
                        <div className="u-width--100p u-spacing">
                          <h3 className="c-block__title u-theme--color--darker u-font--primary--m ">
                            <a
                              href="https://insurance.cc.adventist.org"
                              className="c-block__title-link u-theme--link-hover--dark"
                            >
                              Insurance
                            </a>
                          </h3>
                          <p className="c-block__description">
                            Submit details for your insurance coverage
                          </p>
                        </div>
                        <div className="c-block__meta hide"></div>
                        <ActionLink
                          url="https://insurance.cc.adventist.org"
                          img={handHolding}
                          label="Launch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-grid-item l-grid-item l-grid-item--s--3-col l-grid-item--l--2-col">
                  <div className="c-media-block c-block c-block__stacked u-space--right u-space--double--bottom">
                    <div className="c-block__content u-spacing u-border--left u-color--gray u-theme--border-color--darker--left">
                      <div className="u-spacing c-block__group card-relative">
                        <div className="u-width--100p u-spacing">
                          <h3 className="c-block__title u-theme--color--darker u-font--primary--m ">
                            <a
                              href="https://bookshop.cc.adventist.org/"
                              className="c-block__title-link u-theme--link-hover--dark"
                            >
                              Bookshop
                            </a>
                          </h3>
                          <p className="c-block__description">
                            Get books, tithe envelopes and more
                          </p>
                        </div>
                        <div className="c-block__meta hide"></div>
                        <ActionLink
                          url="https://bookshop.cc.adventist.org/"
                          img={book}
                          label="Launch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </article>
  </section>
  )
}

export default LandingPage
import Aside from './components/Aside';
import Drawer from './components/Drawer';
import Footer from './components/Footer';
import Header from './components/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import Modal from 'react-modal';
import { AppRoutes } from './Routes';
import NiceModal from '@ebay/nice-modal-react';

Modal.setAppElement('#root');

function App() {
  return (
    <Router>
      <NiceModal.Provider>
        <div className="l-wrap">
          <div className="l-wrap__content l-content" role="document">
            <Header />
            <Drawer />
            <main
              className="l-main u-spacing--double u-padding--double--bottom"
              role="main"
            >
              <header className="c-page-header c-page-header__long u-space--zero u-background-color--gray">
                <div className="c-page-header__long--inner l-grid l-grid--7-col ">
                  <div className="c-page-header__content c-page-header__long__content l-grid-wrap l-grid-wrap--5-of-7 u-shift--left--1-col--at-xxlarge ">
                    <span className="c-page-header__kicker o-kicker u-color--white">
                      Online Treasury Services{' '}
                    </span>
                  </div>
                </div>
              </header>

              <AppRoutes />
            </main>
            <Footer />
          </div>
          <Aside />
        </div>
      </NiceModal.Provider>
    </Router>
  );
}

export default App;

import React from 'react';
import LongArrowLeft from './LongArrowLeft';

export default function ActionLink({ url, label, img }) {
  return (
    <div style={{ width: '100%' }}>
      <a
        href={url}
        target="_blank"
        className="c-block__button o-button o-button--outline"
        rel="noreferrer"
      >
        {label}
        <LongArrowLeft />
      </a>
      <img src={img} className="card-image" alt="" />
    </div>
  );
}

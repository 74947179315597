import React from 'react';
import logo from '../assets/images/logo.svg';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="c-header " role="banner" id="header">
      <div className="c-header--inner">
        <div className="c-header__nav-secondary">
          <nav className="c-secondary-nav" role="navigation">
            <ul className="c-secondary-nav__list">
              <li className="c-secondary-nav__list-item c-secondary-nav__list-item__menu c-secondary-nav__list-item__toggle js-toggle-menu is-priority">
                <a
                  href="#"
                  className="c-secondary-nav__link u-font--secondary-nav u-color--gray u-theme--link-hover--base"
                >
                  <span className="u-icon u-icon--xs u-path-fill--gray">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                      <title>o-icon__menu</title>
                      <path
                        d="M0,0H10V2H0ZM0,10H10V8H0ZM0,6H10V4H0Z"
                        fill="#9b9b9b"
                      ></path>
                    </svg>
                  </span>
                  Menu
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="c-header__logo c-logo">
          <a href="/" className="c-logo__link u-theme--path-fill--base">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="c-header__nav-primary">
          <nav
            className="c-primary-nav c-priority-nav"
            role="navigation"
            instance="0"
          >
            <ul className="c-primary-nav__list c-priority-nav__list">
              <li className="c-primary-nav__list-item">
                <Link
                  to="/"
                  className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base this-is-active"
                >
                  Home
                </Link>
              </li>
              <li className="c-primary-nav__list-item">
                <Link
                  to="/reports"
                  className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base this-is-active"
                >
                  Reports
                </Link>
              </li>{' '}
              <li className="c-primary-nav__list-item">
                <Link
                  to="/resources"
                  className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base this-is-active"
                >
                  Resources
                </Link>
              </li>
              <li className="c-primary-nav__list-item has-subnav">
                <a
                  href="#"
                  className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base"
                >
                  More Services
                </a>
                <span className="c-primary-nav__arrow c-subnav__arrow o-arrow--down u-path-fill--gray"></span>
                <ul className="c-primary-nav__subnav c-subnav">
                  <li className="c-primary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light u-theme--border-color--dark">
                    <a
                      className="c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base"
                      href="https://bookshop.cc.adventist.org"
                    >
                      Bookshop
                    </a>
                  </li>
                  <li className="c-primary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light u-theme--border-color--dark">
                    <a
                      className="c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base"
                      href="https://insurance.cc.adventist.org"
                    >
                      Insurance
                    </a>
                  </li>
                  <li className="c-primary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light u-theme--border-color--dark">
                    <a
                      className="c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base"
                      href="https://envelope.cc.adventist.org"
                    >
                      Tithe Envelope
                    </a>
                  </li>
                  <li className="c-primary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light u-theme--border-color--dark">
                    <a
                      className="c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base"
                      href="https://tf.cc.adventist.org"
                    >
                      Trust Fund
                    </a>
                  </li>
                  <li className="c-primary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light u-theme--border-color--dark">
                    <a
                      className="c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base"
                      href="https://order.cc.adventist.org"
                    >
                      Sabbath School Orders
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <span
              className="c-priority-nav__dropdown-wrapper"
              aria-haspopup="false"
            >
              <button
                aria-controls="menu"
                type="button"
                className="js-toggle-menu c-priority-nav__dropdown-toggle c-priority-nav--is-hidden"
                prioritynav-count="0"
              ></button>
              <ul aria-hidden="true" className="c-priority-nav__dropdown"></ul>
            </span>
          </nav>
        </div>
      </div>
    </header>
  );
}

import React from 'react'

export default function Footer() {
    return (
        <footer
        className="c-footer u-theme--background-color--primary u-theme--background-color--darker"
        role="contentinfo"
      >
        <div className="c-footer--inner u-color--white l-grid l-grid--7-col l-grid-wrap l-grid-wrap--6-of-7">
          <div className="l-grid-item--7-col l-grid-item--m--1-col c-footer__logo u-path-fill--white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 169.88 169.88"
            >
              <title>icon-logo-round-sda</title>
              <path
                d="M147.5,117c.67,0,1.07-.37,1.07-.91s-.34-.86-1.08-.86h-.63V117Zm-1.62-2.6h1.65c1.4,0,2,.56,2,1.66a1.49,1.49,0,0,1-1,1.46L150,120h-1.1l-1.28-2.23h-.8V120h-1Zm5.74,2.8a3.93,3.93,0,0,0-4-4,3.87,3.87,0,0,0-4,4,4,4,0,1,0,8,0Zm-8.69,0a4.55,4.55,0,0,1,4.71-4.71,4.62,4.62,0,0,1,4.71,4.71,4.71,4.71,0,1,1-9.42,0Zm-55.08-.31c0-5.71,5.08-10.92,11.13-17l6.71-6.71c4.45-4.45,13.79-13.8,9.89-22.62-.25-.58.17-.87.56-.39,5.9,7.21,3.89,18.43-7.7,30L99,109.64c-6,6-8.89,8.89-10.38,16.15-.11.55-.73.54-.73,0Zm-21.8-23c-6.81,6.82-8.46,14.72-3,19.42.45.39.22.87-.37.6-6.77-3.08-12.1-14.27-.5-25.87l23.2-23.18c6-6,8.88-8.84,10.38-16.1.11-.54.73-.54.73,0v8.9c0,5.7-5.08,10.92-11.13,17Zm-12.27,9.19c-5.9-7.21-3.89-18.43,7.7-30L85.36,49.18c6-6,8.89-8.9,10.38-16.16.12-.54.74-.54.74,0v9c0,5.7-5.09,10.92-11.14,17L64.24,80.06c-4.45,4.44-13.8,13.8-9.9,22.61C54.6,103.25,54.17,103.54,53.78,103.06Zm-4-15.71C47.61,80.47,49,69.92,59,60L85.33,33.57c6-6,8.9-8.85,10.39-16.11.11-.54.73-.54.73,0v8.9c0,5.7-5.08,10.92-11.13,17L62.58,66.06C52,76.61,50.37,81.58,50.42,87.36c0,.61-.5.57-.68,0Zm54.13-8c6.82-6.82,8.46-14.72,3-19.41-.45-.4-.21-.88.38-.61,6.76,3.08,12.09,14.28.5,25.87L99,94c-6,6-8.88,8.84-10.38,16.1-.11.55-.73.54-.73,0v-8.89c0-5.71,5.08-10.92,11.13-17Zm16.32,6.53c2.12,6.88.73,17.42-9.23,27.39l-12,12-1,1H89.77c2-3.47,5.43-6.93,9.23-10.73l8.34-8.34c10.55-10.55,12.21-15.51,12.16-21.29C119.5,85.25,120,85.28,120.19,85.87ZM88.6,141.35c-.11.55-.73.55-.73,0v-8.88a.36.36,0,0,1,.36-.36h4.4a21.27,21.27,0,0,0-4,9.25Zm19.54-.24c-11.45-1.93-17.7,3.52-19.57,8.62-.16.45-.7.38-.7,0V148.1c0-5.71,5.08-10.92,11.13-17l10-10,26.64,4.58,14.12,14.12a84.94,84.94,0,1,0-129.64,0L34.24,125.7l30.24-5.2c14-2.43,17.5.72,17.52,5.35a.37.37,0,0,1-.36.37H72.91a.37.37,0,0,0-.37.36v5.16a.37.37,0,0,0,.37.36h8.74a.37.37,0,0,1,.36.37v17.21c0,.43-.54.5-.7,0-1.87-5.1-8.11-10.55-19.57-8.62l-34.68,6a84.89,84.89,0,0,0,115.76,0l-34.68-6Z"
                fill="#fff"
              ></path>
            </svg>
          </div>
          <div className="l-grid-item l-grid-item--m--3-col c-footer__legal">
            <p className="c-footer__copyright">
              Copyright © 2020 www.cc.adventist.org - Cape Conference of
              Seventh-Day Adventists - All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    )
}
